import React from "react"

import styles from "./../../../policies-css-module.module.scss"
import "../../../../styles.scss"

import { Layout } from "../../../../components"

import SolvDhamakaTnc from "../../../../components/policies/solvDhamaka/solv-dhamaka-tnc"
import { Link } from "gatsby"

const SolvDhamaka = () => {
  return (
    <>
      <Layout>
        <div className={styles.container}>
          <div className={styles.link_section}>
            <div className={styles.link_section_div}>
              <Link
                exact
                className={styles.tc_links}
                activeClassName="is-active"
                to="/promos/solv-dhamaka/tnc/"
              >
                SOLV DHAMAKA
              </Link>
            </div>
          </div>
          <div className={styles.content_section}>
            <SolvDhamakaTnc />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default SolvDhamaka
