import React from "react"

import "./solv-dhamaka-tnc.scss"

const SolvDhamakaTnc = () => {
  return (
    <>
      <div className="main-content">
        <div className="title" style={{ textAlign: "center" }}>
        SOLV DHAMAKA TERMS & CONDITIONS
        </div>
        {/* <p className="sub-heading">DEFINITIONS</p>
         */}
          
            <p>
            The contest period shall be total of 90 days starting 17th April 2024 on GTV..
            </p>
          
          
            <p>
            The giveaway/price for the contest cannot be converted to cash and is non-transferable.
            </p>
          
          
            <p>
            Any tax obligation arising out of the Prize, the winner will be responsible for fulfilling those obligations. 
            Winners agrees to provide necessary documentation including its Permanent Account Number or suitable declarations, as may be required, 
            to satisfy any information reporting or withholding obligations with respect to any payments under this contest.
            </p>
          
          
          
            <p>
            By agreeing to participate in this Contest, you agree 
            (a) to be bound by these terms; 
            (b) your age is alteast 18 years and you are a citizen of India; 
            (c) you have a valid proof of identity and age, which you will be required to share for delivery of the Prize.
            </p>
          
          
            <p>
            The Prize is awarded "as is" and without warranty of any kind,
             express or implied (including, without limitation, any implied warranty of merchantability or fitness for a particular purpose).
            </p>
          
          
            <p>
            These terms and conditions are governed by the laws of India and
            courts at Bengaluru will have the exclusive jurisdiction in respect of any disputes or any matter arising here from.
            </p>
          
          
            <p>
            Solv may, in its sole discretion, disqualify any individual found to be - 
            (a) tampering with the Contest; or 
            (b) acting in violation of these terms.
            </p>
          
     
        <p>
        You agree that all decisions made by Solv related to the Contest are final and binding on you;
       Notwithstanding the foregoing, Solv's aggregate liability howsoever arising in relation to this Contest will not exceed the monetary value of the Prize.

        </p>

        <p>
          Please note that transactions where 10% or 12% manual discount coupons have been used shall not be counted towards the Quarterly Target for the contest.
        </p>
        
      </div>
    </>
  )
}

export default SolvDhamakaTnc
